import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'

import style from './OurPartners.style'

const OurPartnersStyled = styled.div(props => ({ ...style(props) }))

const OurPartners = ({ partners }) => {
    return (
        <OurPartnersStyled>
            <ul className="OurPartners-logos">
                {partners.map(partner => (
                    <li key={partner.id}>
                        <a href={partner.acf.link} target="_blank" rel="noopener noreferrer">
                            <img src={partner.featuredImage.node.sourceUrl} alt={partner.featuredImage.node.altText} />
                        </a>
                    </li>
                ))}
            </ul>
        </OurPartnersStyled>
    )
}

OurPartners.propTypes = {
    partners: PropTypes.array.isRequired
}

export default withTheme(OurPartners)
