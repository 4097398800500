import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import style from './BlockTitle.style'

const BlockTitleStyled = styled.div(props => ({ ...style(props) }))

const BlockTitle = ({
    title,
    subtitle,
    titleTag: TitleTag,
    subtitleTag: SubtitleTag,
    paddingLeft,
    variation,
    noWordBreak
}) => {
    return (
        <BlockTitleStyled paddingLeft={paddingLeft} variation={variation} noWordBreak={noWordBreak}>
            <TitleTag className="title" dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && <SubtitleTag className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </BlockTitleStyled>
    )
}

BlockTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    titleTag: PropTypes.string,
    subtitleTag: PropTypes.string,
    paddingLeft: PropTypes.number,
    variation: PropTypes.string,
    noWordBreak: PropTypes.bool
}

BlockTitle.defaultProps = {
    title: 'Your Title Here',
    subtitle: undefined,
    titleTag: 'h2',
    subtitleTag: 'p',
    paddingLeft: 0,
    variation: undefined,
    noWordBreak: false
}

export default withTheme(BlockTitle)
