import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    margin: '0 auto 0 auto',
    maxWidth: 1380,
    padding: toRem(40),

    [theme.breakpoints.up('md')]: {
        padding: toRems([40, 20])
    },

    '.OurPartners-logos': {
        listStyle: 'none',
        padding: 0,
        marginTop: toRem(40),
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',

        li: {
            width: '30%',
            marginBottom: toRem(30),
            textAlign: 'center',

            [theme.breakpoints.up('md')]: {
                width: '15%',
                padding: 0
            },

            '&:first-of-type': {
                img: {
                    maxWidth: 90,

                    [theme.breakpoints.up('md')]: {
                        maxWidth: 100
                    },

                    [theme.breakpoints.up('xl')]: {
                        maxWidth: 120
                    }
                }
            },

            img: {
                [theme.breakpoints.up('lg')]: {
                    transition: 'transform 0.5s ease',
                    '&:hover': {
                        transform: 'scale(1.1, 1.1)'
                    }
                }
            }
        }
    }
})

export default style
