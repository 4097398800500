import { toRem } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme, paddingLeft, variation, noWordBreak }) => ({
    paddingLeft: toRem(paddingLeft),

    '& .title': {
        color: variation === 'secondary' ? theme.colors.white : theme.colors.goldDark,
        fontSize: variation === 'secondary' ? toRem(20) : toRem(36),
        fontFamily: variation === 'secondary' ? 'Poppins' : undefined,
        fontWeight: variation === 'secondary' ? theme.typography.fontWeight.regular : 600,
        ...(!noWordBreak && {
            wordBreak: 'break-all'
        }),

        [theme.breakpoints.up('lg')]: {
            fontSize: variation === 'secondary' ? toRem(40) : toRem(50)
        }
    },
    '& .subtitle': {
        marginBottom: toRem(22),
        fontSize: toRem(20),

        [theme.breakpoints.up('lg')]: {
            marginBottom: toRem(40),
            fontSize: toRem(24)
        }
    }
})

export default style
