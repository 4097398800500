import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import { i18n as i18next, withTranslation } from 'server/i18n'

import BlockTitle from 'components/BlockTitle'

import style from './Testimonials.style'

const TestimonialsStyled = styled.div(props => ({ ...style(props) }))

const Testimonials = ({ testimonials, title, subtitle, i18n }) => {
    const params = {
        slidesPerView: 1,
        spaceBetween: 20,
        releaseOnEdges: true,
        autoHeight: true,
        pagination: {
            el: '.TestimonialsPagination',
            clickable: true,
            bulletActiveClass: 'active'
        }
    }

    return (
        <TestimonialsStyled>
            <BlockTitle title={title} subtitle={subtitle} paddingLeft={0} />

            <div className="TestimonialSwiper">
                <Swiper {...params}>
                    {testimonials.map(testimonial => (
                        <div key={testimonial.id} className="TestimonialSlide">
                            <p className="TestimonialText">
                                “
                                {i18n.language === 'en'
                                    ? testimonial.acf.testimonialText
                                    : testimonial.acf.testimonialTextDe}
                                ”
                            </p>

                            <p className="TestimonialPerson">
                                <span className="Name">{testimonial.title}</span>
                                <span className="Boat">
                                    {i18n.language === 'en' ? testimonial.acf.boat : testimonial.acf.boatDe}
                                </span>
                            </p>
                        </div>
                    ))}
                </Swiper>
            </div>
        </TestimonialsStyled>
    )
}

Testimonials.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            acf: PropTypes.object,
            title: PropTypes.string
        })
    ).isRequired,
    i18n: PropTypes.oneOfType([() => null, PropTypes.object]).isRequired
}

export default withTranslation()(withTheme(Testimonials))
