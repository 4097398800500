import { toRem, toRems } from 'helpers/theme'

/* eslint-disable quote-props */
const style = ({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between'
    },

    '& .TestimonialSwiper': {
        [theme.breakpoints.up('md')]: {
            maxWidth: '60%',
            marginTop: 10
        },

        [theme.breakpoints.up('lg')]: {
            maxWidth: '50%',
            marginTop: 20
        },

        [theme.breakpoints.up('xxl')]: {
            maxWidth: '60%'
        }
    },

    '& .TestimonialText': {
        fontSize: toRem(20),
        marginBottom: toRem(40),

        [theme.breakpoints.up('xl')]: {
            fontSize: toRem(36),
            marginBottom: toRem(100)
        },

        [theme.breakpoints.up('xxl')]: {
            fontSize: toRem(40),
            marginBottom: toRem(150)
        }
    },

    '& .TestimonialPerson': {
        maxWidth: 180,

        [theme.breakpoints.up('lg')]: {
            maxWidth: 370
        },

        '.Name, .Boat': {
            display: 'block',
            fontSize: toRem(14),

            [theme.breakpoints.up('xl')]: {
                fontSize: toRem(20)
            }
        },

        '.Boat': {
            color: theme.colors.goldDarker
        }
    },

    '& .TestimonialsPagination': {
        display: 'inline-flex',
        alignItems: 'center',
        position: 'absolute',
        bottom: 5,
        right: 0,
        left: 'auto',
        width: 'auto',
        zIndex: 9,
        transformOrigin: 'center center',

        '.swiper-pagination-bullet': {
            position: 'relative',
            width: 12,
            height: 12,
            opacity: 1,
            marginRight: 10,
            outline: 'none',
            cursor: 'pointer',

            [theme.breakpoints.up('lg')]: {
                width: 24,
                height: 24
            },

            '&:before': {
                content: '""',
                position: 'absolute',
                top: 3,
                left: 3,
                borderRadius: '50%',
                width: 6,
                height: 6,
                background: theme.colors.blueLight,
                transition: 'background 0.5s ease',

                [theme.breakpoints.up('lg')]: {
                    top: '50%',
                    left: '50%',
                    marginTop: -3,
                    transform: 'translateX(-50%)'
                }
            },

            '&:last-of-type': {
                marginRigt: 0
            },

            [theme.breakpoints.up('xl')]: {
                marginRight: 15
            },

            '&.active': {
                '&:before': {
                    width: 12,
                    height: 12,
                    top: 0,
                    left: 0,
                    background: theme.colors.goldDark,

                    [theme.breakpoints.up('lg')]: {
                        top: '50%',
                        left: '50%',
                        marginTop: -6
                    }
                }
            }
        }
    }
})

export default style
